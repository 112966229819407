import React, { useContext, useEffect } from 'react';
import SEO from '../components/seo';
import { Layout } from '../components/Layout';
import { FormField, Input, Textarea } from '@adamwebster/fused-components';
import { HTag } from '../components/HTag';
import { StyledButton } from '../components/StyledButton';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { styleVariables } from '../styles/Variables';
import { SiteContext } from '../state';

const StyleContact = styled.div`
  display: flex;
  form {
    flex: 1 1;
  }

  @media (max-width: 600px) {
    flex-flow: column-reverse;
  }
`;

const StyledContactCard = styled.div`
  flex: 0 0 auto;
  margin-right: 20px;
  background-color: #404040;
  color: #fff;
  height: 180px;
  border-radius: ${styleVariables.borderRadius};
  padding: 20px;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-top: 20px;
  }
`;
const StyledParagraphFlex = styled.p`
  display: flex;
  margin-bottom: 50px;
  svg {
    margin-right: 20px;
    margin-top: 5px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;
const Contact = () => {
  const { dispatch } = useContext(SiteContext);
  useEffect(() => {
    dispatch({ type: 'SET_ACTIVE_PAGE', payload: 'contact' });
  }, []);
  return (
    <>
      <Layout hasHero={false}>
        <SEO title="Contact" />
        <HTag pageHeader>Contact</HTag>
        <StyleContact>
          <StyledContactCard>
            <StyledParagraphFlex>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              548 Market St,
              <br />
              San Francisco, CA
              <br /> 94104-5401, US
            </StyledParagraphFlex>{' '}
            <StyledParagraphFlex>
              <FontAwesomeIcon icon={faPhone} />
              (415)-555-3456
            </StyledParagraphFlex>
          </StyledContactCard>
          <form onSubmit={(e) => e.preventDefault()}>
            <FormField label="Your Name">
              <Input id="name" />
            </FormField>
            <FormField label="Your Email">
              <Input id="email" type="email" />
            </FormField>
            <FormField label="Message">
              <Textarea rows={15} id="Message" />
            </FormField>
            <StyledButton primary>Send</StyledButton>
          </form>
        </StyleContact>
      </Layout>
    </>
  );
};

export default Contact;
